/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
/** @namespace Geely/Component/CheckmarkIcon/Component */
export class CheckmarkIcon extends PureComponent {
    static propTypes = {
        elem: PropTypes.string
    };

    static defaultProps = {
        elem: 'Icon'
    };

    render() {
        const { elem } = this.props;
        return (
            <svg className={ `CheckmarkIcon-${elem}` } width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M12.8332 3.17969L14.0823 4.42873L5.92241 12.5886L1.9292 8.5954L3.17824 7.34635L5.92241 10.0905L12.8332 3.17969Z" fill="#0067B2" />
            </svg>
        );
    }
}

export default CheckmarkIcon;
