import { withRouter } from 'react-router-dom';

import ProductCard from 'Component/ProductCard';
import { ProductListPage as JaidahProductListPage } from 'JaidahComponent/ProductListPage/ProductListPage.component';
import { GRID_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import { setLoadedFlag } from 'Util/Request/LowPriorityLoad';

// import { setLoadedFlag } from 'Util/Request/LowPriorityLoad';
import './ProductListPage.geely.style';

/** @namespace Geely/Component/ProductListPage/Component */
// eslint-disable-next-line @scandipwa/scandipwa-guidelines/derived-class-names
export class ProductListPage extends JaidahProductListPage {
    renderPlaceholders() {
        const {
            numberOfPlaceholders, mix: {
                mods: {
                    layout = GRID_LAYOUT
                } = {}
            }
        } = this.props;

        return Array.from(
            { length: numberOfPlaceholders },
            (_, i) => (
                <ProductCard
                  key={ i }
                  product={ {} }
                  layout={ layout }
                  onLoad={ setLoadedFlag }
                />
            )
        );
    }

    renderPageItems() {
        const {
            items,
            selectedFilters,
            mix: {
                mods: {
                    layout = GRID_LAYOUT
                } = {}
            }
        } = this.props;

        return items.map((product, i) => (
            <ProductCard
              product={ product }
              // eslint-disable-next-line react/no-array-index-key
              key={ i }
              selectedFilters={ selectedFilters }
              layout={ layout }
              onLoad={ setLoadedFlag }
              { ...this.containerProps() }
            />
        ));
    }
}

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/derived-class-names
export default withRouter(ProductListPage);
