export const CHEVROLET_STORE_ENG = 'eng';
export const LANG_ENG = 'English';
export const LANG_AR = 'Arabic';

// updated so that if the default store code is  not found in the store, it checks the url for it
const getStoreView = (store) => {
    const { ConfigReducer: { base_link_url = window.location.href, code } = {} } = store || {};
    const { pathname: storePrefix } = new URL(base_link_url);

    const url1stPart = storePrefix.split('/')[1];

    const urlStoreCode = window.storeList.includes(url1stPart) ? url1stPart : CHEVROLET_STORE_ENG;

    return urlStoreCode || code;
};

// updated so that if the default language is not found in the store, it checks the url for it
const getLanguage = (store) => {
    const { ConfigReducer: { store_name } = {} } = store;

    return store_name || getStoreView(store) === CHEVROLET_STORE_ENG ? LANG_ENG : LANG_AR;
};

export default {
    'GtmNew/EventData/General/Data/getStoreView': {
        function: getStoreView
    },
    'GtmNew/EventData/General/Data/getLanguage': {
        function: getLanguage
    }
};
