import { connect } from 'react-redux';

import {
    ATTRIBUTE_CODES_ARRAY,
    TRIM
} from 'Component/ProductActions/ProductActions.config';
import {
    mapDispatchToProps, mapStateToProps,
    ProductContainer as JaidahProductContainer
} from 'JaidahComponent/Product/Product.container';
import fromCache from 'Util/Cache/Cache';
import { history } from 'Util/History';
import { getNewParameters, getVariantIndex } from 'Util/Product';
import { getProductInStock } from 'Util/Product/Extract';
import { setQueryParamsWithoutHistory } from 'Util/Url';

import { OUT_OF_STOCK, PARAM_LIST } from './Product.config';

export {
    mapStateToProps,
    mapDispatchToProps
};

/** @namespace Geely/Component/Product/Container */
export class ProductContainer extends JaidahProductContainer {
    // overriden to return alternative product if the one be selected is out of stock
    updateConfigurableVariant(key, value, checkEmptyValue = false) {
        /**
         * Used determine which attributes should be changed when variant is not found based on key value pair
         * Ex: one engine attribute is selected in the left menu, but the variant that contains that specific engine was deleted.
         * In order to get an existing variant, we need to change attributes that come after engine, i.e. color_exterior or color_interior values
         * so that they match an existing variant
         */
        const { parameters: prevParameters, selectedProduct } = this.state;
        const { product: { variants, configurable_options } } = this.props;

        const { location } = history;

        const newParameters = getNewParameters(prevParameters, key, value);

        const { [key]: oldValue, ...currentParameters } = newParameters;
        const parameters = oldValue === '' && checkEmptyValue ? currentParameters : newParameters;

        this.setState({ parameters });

        const configurableOptionsKeys = Object.keys(configurable_options);
        const newIndex = Object.keys(parameters).length === configurableOptionsKeys.length
            ? getVariantIndex(variants, parameters, false)
            // Not all parameters are selected yet or there are no product in stock, therefore variantIndex must be invalid
            : -1;

        /**
         * Overridden to get variant if no product was found after searching based on selected parameter
         */
        if (newIndex !== -1) {
            const getAltProduct = variants[newIndex].stock_status === OUT_OF_STOCK && key === TRIM;

            // If variant was found & in stock, set it as the selected product if not in stock get in stock alt product
            const altProduct = getAltProduct && variants.find(
                (variant) => (variant.stock_status !== OUT_OF_STOCK
                    && variant.attributes[key]?.attribute_value === value)
            );
            const altParameters = getAltProduct && PARAM_LIST.reduce(
                (parameters, currentParam) => getNewParameters(parameters,
                    currentParam,
                    altProduct?.attributes[currentParam].attribute_value),
                { ...parameters }
            );

            const newProduct = altProduct || variants[newIndex];
            const newParameters = altProduct ? altParameters : parameters;

            if (newProduct && newProduct !== selectedProduct) {
                this.setState({
                    selectedProduct: newProduct,
                    parameters: newParameters
                });
                setQueryParamsWithoutHistory(newParameters, location, history);
            }

            return;
        }

        // eslint-disable-next-line fp/no-let
        let memorizedOutOfStockVariant = {};

        // Otherwise, get the variant that has the selected key=>value pair and all the already selected attributes
        const newProduct = variants.find(
            (variant) => {
                // First, check whether a product is in stock or not
                const isInStock = fromCache(getProductInStock, [variant]);

                // Get the ranks that are before the attribute
                const indexOfKey = ATTRIBUTE_CODES_ARRAY.indexOf(key);
                const ranksBeforeKey = indexOfKey !== -1
                    ? ATTRIBUTE_CODES_ARRAY.slice(0, indexOfKey)
                    : [];

                // If it is the first attribute, get the first variant that has that specific value
                if (ranksBeforeKey.length === 0) {
                    if (isInStock) {
                        return variant.attributes?.[key].attribute_value === value;
                    }

                    const isMemorizedOutOfStockVariantSet = Object.keys(memorizedOutOfStockVariant).length !== 0;

                    if (variant.attributes?.[key].attribute_value === value && !isMemorizedOutOfStockVariantSet) {
                        memorizedOutOfStockVariant = variant;
                    }

                    return false;
                }

                if (!isInStock) {
                    return false;
                }

                // Filter based on lower attribute values
                const checkLowerRankedAttributes = ranksBeforeKey.every(
                    (element) => variant.attributes?.[element].attribute_value === parameters[element]
                );

                return variant.attributes?.[key].attribute_value === value && checkLowerRankedAttributes;
            }
            // if no variant was in stock
        ) ?? memorizedOutOfStockVariant;

        // Get new params from the selected variant
        const paramsAfterNewIndex = configurableOptionsKeys
            .reduce((params, option) => {
                // eslint-disable-next-line no-param-reassign
                params[option] = newProduct.attributes?.[option].attribute_value;

                return params;
            }, {});

        // Set the new parameters and the new product
        if (newProduct && Object.keys(newProduct).length && newProduct !== selectedProduct) {
            this.setState({
                selectedProduct: newProduct,
                parameters: paramsAfterNewIndex
            });
            setQueryParamsWithoutHistory(paramsAfterNewIndex, location, history);
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainer);
