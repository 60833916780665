/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import PRODUCT_TYPE from 'Component/Product/Product.config';
import {
    ProductPriceComponent as JaidahProductPriceComponent
} from 'JaidahComponent/ProductPrice/ProductPrice.component';

/** @namespace Geely/Component/ProductPrice/Component */
export class ProductPriceComponent extends JaidahProductPriceComponent {
    static defaultProps = {
        ...JaidahProductPriceComponent.defaultProps,
        priceCurrency: 'QAR',
        isSchemaRequired: true
    };

    priceLabelTypeMap = {
        ...this.priceLabelTypeMap,
        [PRODUCT_TYPE.configurable]: __('Starting at')
    };

    renderConfigurablePrice() {
        const {
            price: {
                finalPriceExclTax = {},
                finalPrice = {}
            },
            priceType,
            isPlp
        } = this.props;

        const { [priceType]: label } = this.priceLabelTypeMap;

        if (!isPlp) {
            return this.renderDefaultPrice();
        }

        return this.renderPriceWithOrWithoutTax(finalPrice, finalPriceExclTax, label);
    }

    // removed highPrice
    getCurrentPriceSchema() {
        const {
            isSchemaRequired,
            price: {
                finalPrice: {
                    value: contentPrice = 0
                } = {}
            } = {}
        } = this.props;

        return isSchemaRequired ? { itemProp: 'price', content: contentPrice } : {};
    }

    // removed highPrice item prop
    renderOldPrice() {
        const {
            price: {
                originalPrice: {
                    value: originalPriceValue,
                    valueFormatted: originalPriceFormatted
                } = {}
            } = {},
            discountPercentage
        } = this.props;

        if (discountPercentage === 0 || originalPriceValue === 0) {
            return null;
        }

        return (
            <del
              block="ProductPrice"
              elem="HighPrice"
              aria-label={ __('Old product price') }
            >
                { originalPriceFormatted }
            </del>
        );
    }
}

export default ProductPriceComponent;
