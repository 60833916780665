import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    MyAccountCreateAccountContainer as JaidahMyAccountCreateAccountContainer
} from 'JaidahComponent/MyAccountCreateAccount/MyAccountCreateAccount.container';

/** @namespace Geely/Component/MyAccountCreateAccount/Container */
export class MyAccountCreateAccountContainer extends JaidahMyAccountCreateAccountContainer {
    onError() {
        const { showNotification } = this.props;

        showNotification('info', __('Please fill out the required field.'));
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountCreateAccountContainer);
