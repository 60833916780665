export const renderInlineStyleForText = () => ({
    position: 'absolute',
    insetInlineEnd: '20px',
    insetBlockStart: '20px',
    zIndex: '1'
});

export const renderStyle = (args, callback) => {
    const result = callback(...args);

    return result.replace('margin: 0 -30px 0;', '');
};

export default {
    'ScandipwaAmastyLabels/Component/ProductLabel/Component': {
        'member-function': {
            renderInlineStyleForText,
            renderStyle,
            renderImage: () => null
        }
    }
};
