import {
    getInitialState,
    ProductOptionsReducer as jaidahProductOptionsReducer
} from 'JaidahStore/ProductOptions/ProductOptions.reducer';
import { REMOVE_OPTIONS_CATEGORY } from 'Store/ProductOptions/ProductOptions.action';

export * from 'JaidahStore/ProductOptions/ProductOptions.reducer';

/** @namespace Geely/Store/ProductOptions/Reducer/ProductOptionsReducer */
export const ProductOptionsReducer = (
    state = getInitialState(),
    action
) => {
    const { type, payload } = action;

    switch (type) {
    case REMOVE_OPTIONS_CATEGORY:
        const categoryName = payload;
        const {
            categorizedItems,
            categorizedItems: {
                [categoryName]: categoryItemsSKUs
            } = {},
            items = {},
            categorizedItemsTotals,
            categorizedItemsTotals: {
                [categoryName]: categoryItemsTotal
            } = {},
            total,
            regularTotal
        } = state;

        const itemsEntries = Object.entries(items);
        const filteredItems = Object.fromEntries(itemsEntries.filter(([sku]) => !categoryItemsSKUs.includes(sku)));

        return {
            ...state,
            categorizedItems: {
                ...categorizedItems,
                [categoryName]: []
            },
            items: filteredItems,
            categorizedItemsTotals: {
                ...categorizedItemsTotals,
                [categoryName]: 0
            },
            total: total - categoryItemsTotal,
            regularTotal: regularTotal - categoryItemsTotal
        };
    default:
        return jaidahProductOptionsReducer(state, action);
    }
};

export default ProductOptionsReducer;
