export const RETRY_DELAY = 50;
export const EV_PRIORITY_LOAD_END = 'pr_end';

/** @namespace Scandipwa/Util/Request/LowPriorityLoad/waitForPriorityLoad */
export const waitForPriorityLoad = () => new Promise((resolve) => {
    function waitForIt() {
        if (window.isPriorityLoaded) {
            const ev = new Event(EV_PRIORITY_LOAD_END);
            document.dispatchEvent(ev);
            resolve();
            return;
        }

        setTimeout(waitForIt, RETRY_DELAY);
    }

    waitForIt();
});

/** @namespace Scandipwa/Util/Request/LowPriorityLoad/setLoadedFlag */
export const setLoadedFlag = () => {
    window.isPriorityLoaded = true;
};
