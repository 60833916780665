/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
import ChevronIcon from 'Component/ChevronIcon/ChevronIcon.component';
import { LEFT, RIGHT } from 'Component/ChevronIcon/ChevronIcon.config';
import { SliderComponent as SourceSlider } from 'JaidahComponent/Slider/Slider.component';

import './Slider.geely.style';

/** @namespace Geely/Component/Slider/Component */
export class Slider extends SourceSlider {
    renderArrows() {
        const { showArrows, activeImage, children } = this.props;
        const nextIsDisabled = activeImage + 1 === children.length;
        const prevIsDisabled = activeImage === 0;
        const areArrowsHidden = nextIsDisabled && prevIsDisabled;

        if (!showArrows) {
            return null;
        }

        return (
            <>
                <button
                  block="Slider"
                  elem="Arrow"
                  mods={ { isPrev: true, isDisabled: prevIsDisabled, isHidden: areArrowsHidden } }
                  aria-label={ __('Previous') }
                  onClick={ this.goPrev }
                >
                    <ChevronIcon isGeelyArrow direction={ LEFT } />
                </button>
                <button
                  block="Slider"
                  elem="Arrow"
                  mods={ { isNext: true, isDisabled: nextIsDisabled, isHidden: areArrowsHidden } }
                  aria-label={ __('Next') }
                  onClick={ this.goNext }
                >
                    <ChevronIcon isGeelyArrow direction={ RIGHT } />
                </button>
            </>
        );
    }

    goPrev(e) {
        e.stopPropagation();
        super.goPrev();
    }

    goNext(e) {
        e.stopPropagation();
        super.goNext();
    }

    /**
     * Overridden to add +2px gap
     */
    getSlideWidth() {
        const { isVertical } = this.props;
        const { offsetWidth = 0, offsetHeight = 0 } = this.draggableRef.current || {};

        // eslint-disable-next-line no-magic-numbers
        return isVertical ? offsetHeight + 2.4 : offsetWidth + 2.4;
    }

    /**
     * Overridden to make slider cycle (1 -> 2 -> 3 -> 1 -> 2 -> ...)
     */
    onClickChangeSlide(state, slideSize, lastTranslate, fullSliderSize) {
        const { originalX } = state;
        const { prevActiveImage: prevActiveSlider } = this.state;
        const {
            onActiveImageChange, device, onClick, disableSingleClick = false, children
        } = this.props;

        const dir = this.getDir();

        if (disableSingleClick) {
            return -(dir * prevActiveSlider);
        }

        if (onClick) {
            onClick();

            return -(dir * prevActiveSlider);
        }

        const fullSliderPoss = Math.round(fullSliderSize / slideSize);
        const elementPositionInDOM = this.draggableRef.current.getBoundingClientRect().x;

        // Calculated slider position based on direction
        const sliderPosition = -prevActiveSlider * dir;

        const realElementPositionInDOM = elementPositionInDOM - lastTranslate;
        const mousePositionInElement = originalX - realElementPositionInDOM;

        if (device.isMobile) {
            return sliderPosition;
        }

        // If the direction is rtl, slider position should always be greater than 0 when trying to scroll right
        const isLastRGalleryElem = (dir === 1 && -fullSliderPoss < sliderPosition)
        || (dir === -1 && sliderPosition > 0);

        if (slideSize / 2 < mousePositionInElement) {
            if (isLastRGalleryElem) {
                const activeSlide = sliderPosition - 1;
                onActiveImageChange(-activeSlide * dir);

                return activeSlide;
            }

            onActiveImageChange(0);
            return 0;
        }

        // If the direction is rtl, slide position must be lower than the total number of slides when trying to scroll to the left
        const isLastLGalleryElem = (dir === -1 && fullSliderPoss > sliderPosition)
        || (dir === 1 && lastTranslate);

        if (slideSize / 2 > mousePositionInElement) {
            if (isLastLGalleryElem) {
                const activeSlide = sliderPosition + 1;
                onActiveImageChange(-activeSlide * dir);

                return activeSlide;
            }

            const activeSlide = children.length - 1;
            onActiveImageChange(activeSlide);
            return -activeSlide * dir;
        }

        return sliderPosition;
    }
}

export default Slider;
