import { addOptionsToAttributes } from 'Util/Product/Product';

import {
    EVENT_GTM_PRODUCT_ADD_TO_CART
} from '../component/GoogleTagManager/GoogleTagManager.events';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        parentProduct: state.ProductReducer?.product
    };
};

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData))
    };
};

const beforeHandleSuccessSave = (args, callback, instance) => {
    const {
        getActiveProduct,
        productOptionsData: {
            items: productOptions = {},
            insurance: {
                product: insuranceProduct = {},
                value: insurancePrice
            } = {}
        },
        parentProduct,
        event,
        parentProduct: {
            sku: parentSku
        }
    } = instance.props;

    const product = getActiveProduct();
    const { attributes: attributesWithOptions, name, categories } = parentProduct;

    const insuranceProductWithPrice = { ...insuranceProduct, price: insurancePrice };
    const options = [...Object.values(productOptions), insuranceProductWithPrice];

    event(EVENT_GTM_PRODUCT_ADD_TO_CART, {
        product: {
            ...product,
            attributes: addOptionsToAttributes(attributesWithOptions, product.attributes),
            name,
            categories,
            parentSku
        },
        options
    });

    return callback(...args);
};

export default {
    'Scandipwa/Component/AddToCart/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Scandipwa/Component/AddToCart/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Scandipwa/Component/AddToCart/Container': {
        'member-function': {
            handleSuccessSave: beforeHandleSuccessSave
        }
    }
};
