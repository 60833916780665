import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps, ProductAttributesContainer as JaidahProductAttributes
}
from 'JaidahComponent/ProductAttributes/ProductAttributes.container';

export {
    mapDispatchToProps
};

/** @namespace Geely/Component/ProductAttributes/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    currency: state.ConfigReducer.default_display_currency_code
});

/** @namespace Geely/Component/ProductAttributes/Container */
export class ProductAttributesContainer extends JaidahProductAttributes {
    containerProps() {
        const {
            optionsData: {
                categorizedItemsTotals = {},
                categorizedItems = {}
            } = {},
            currency,
            productPrice
        } = this.props;

        return {
            ...super.containerProps(),
            categorizedItemsTotals,
            categorizedItems,
            currency,
            productPrice
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductAttributesContainer);
