import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { mapStateToProps } from 'Component/MyAccountOverlay/MyAccountOverlay.container';
import { ACCOUNT_REGISTRATION_URL } from 'Route/MyAccount/MyAccount.config';
import {
    LoginAccountContainer as SourceLoginAccountContainer,
    mapDispatchToProps as sourceMapDispatchToProps
} from 'SourceRoute/LoginAccount/LoginAccount.container';
import { updateMeta } from 'Store/Meta/Meta.action';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

import LoginAccount from './LoginAccount.component';

export { mapStateToProps };

/** @namespace Scandipwa/Route/LoginAccount/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    updateMeta: (meta) => dispatch(updateMeta(meta))
});

/** @namespace Scandipwa/Route/LoginAccount/Container */
export class LoginAccountContainer extends SourceLoginAccountContainer {
    componentDidMount() {
        super.componentDidMount();
        this.updateMetaTitle();
    }

    updateMetaTitle() {
        const { updateMeta } = this.props;

        updateMeta({
            title: __('Log In')
        });
    }

    containerProps() {
        const { location } = this.props;

        return {
            ...super.containerProps(),
            location
        };
    }

    onCreateAccountClick() {
        history.push(appendWithStoreCode(`${ ACCOUNT_REGISTRATION_URL }`),
            { ...history.location.state });
    }

    render() {
        return (
            <LoginAccount
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(LoginAccountContainer)
);
