/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-bind */
import { PureComponent } from 'react';

import ChevronIcon from 'Component/ChevronIcon';
import Form from 'Component/Form';
import Image from 'Component/Image/Image.component';
import { CAR_MODEL, MODEL_YEAR, TRIM } from 'Component/ProductActions/ProductActions.config';
import ProductAttributes from 'Component/ProductAttributes';
import ProductMonthlyPrice from 'Component/ProductMonthlyPrice';
import { DEALERS_OFFER_STEP } from 'Route/OrderReservation';
import { formatPrice } from 'Util/Price/Price';

import './DealersOfferStep.style.scss';

/** @namespace Scandipwa/Component/DealersOfferStep/Component */
export class DealersOfferStepComponent extends PureComponent {
    renderHeader() {
        return (
            <div block="DealersOfferStep" elem="Header">
                <p block="DealersOfferStep" elem="SubHeading">
                    { __('Your quotation is valid for 7 days from the quote creation date.') }
                </p>
            </div>
        );
    }

    renderModelBlock() {
        const { carProduct: { attributes = {}, image = {} } = {}, getAttributeTextValue, baseOrderInfo } = this.props;
        const {
            [CAR_MODEL]: {
                attribute_value: modelValue = '',
                attribute_options: modelOptions = ''
            } = {}
        } = attributes;
        const name = modelOptions?.[modelValue]?.label || '';
        const modelYear = getAttributeTextValue(attributes[MODEL_YEAR]) || '';
        const modelTrim = getAttributeTextValue(attributes[TRIM]) || '';
        const modelName = `${modelYear} ${name} ${modelTrim}`;
        const { url = '', label = '' } = image;
        const { created_at = '', increment_id = '' } = baseOrderInfo;

        const baseInfoData = [
            {
                label: __('Quotation No.'),
                value: increment_id
            },
            {
                label: __('Offered Date'),
                value: created_at
            }
        ];

        return (
            <div block="DealersOfferStep" elem="ModelBlock">
                <div block="DealersOfferStep" elem="ImageBlock">
                    <Image
                      src={ url }
                      alt={ label }
                      ratio="16x9"
                    />
                </div>
                <div block="DealersOfferStep" elem="BaseInfoBlock">
                    <h3 block="DealersOfferStep" elem="ModelName">{ modelName }</h3>
                    { baseInfoData.map(({ label, value }) => (
                        <div key={ label } block="DealersOfferStep" elem="BaseInfoEntity">
                            <span block="DealersOfferStep" elem="BaseInfoLabel">{ label }</span>
                            <span block="DealersOfferStep" elem="BaseInfoValue">{ value }</span>
                        </div>
                    )) }
                </div>
            </div>
        );
    }

    renderProductAttributes() {
        const {
            carProduct,
            carProduct: {
                price_range: {
                    minimum_price: {
                        final_price: {
                            value
                        } = {}
                    } = {}
                } = {}
            } = {},
            optionsDataForOrder,
            baseOrderInfo: { currency_code = 'USD' },
            isIos,
            insuranceData
        } = this.props;

        if (!carProduct || !optionsDataForOrder) {
            return null;
        }

        const price = {
            value,
            valueFormatted: formatPrice(value, currency_code)
        };
        const isNotIos = !isIos;

        return (
            <div block="DealersOfferStep" elem="ProductAttributes" mods={ { isNotIos } }>
                <ProductAttributes
                  product={ carProduct }
                  areDetailsLoaded
                  isContentExpandable={ false }
                  isDealersOfferStep
                  price={ price }
                  optionsDataForOrder={ optionsDataForOrder }
                  insuranceDataForOrder={ insuranceData }
                />
            </div>
        );
    }

    renderBottomBlock() {
        return (
            <div block="DealersOfferStep" elem="BottomBlock">
                { this.renderTotalBlock() }
                { this.renderActions() }
            </div>
        );
    }

    renderTotalBlock() {
        const {
            baseOrderInfo: { grand_total = '', currency_code = 'USD' },
            isMobile
        } = this.props;

        const price = formatPrice(grand_total, currency_code);

        const totalTextContent = isMobile ? __('Total') : __('Total Amount');

        return (
            <div block="DealersOfferStep" elem="TotalInfoBlock">
                <div block="DealersOfferStep" elem="TotalText">
                    <span>{ totalTextContent }</span>
                </div>
                <div block="DealersOfferStep" elem="TotalAmountBlock">
                    <span block="DealersOfferStep" elem="TotalAmount">{ price }</span>
                    <ProductMonthlyPrice
                      block="DealersOfferStep"
                      elem="TotalAmountPerMonth"
                      price={ grand_total }
                      currency={ currency_code }
                      id="DealerOffer"
                    />
                </div>
            </div>
        );
    }

    renderActions() {
        return (
            <div block="DealersOfferStep" elem="ButtonBlock">
                <button
                  type="submit"
                  block="Button"
                  mix={ { block: 'CheckoutBilling', elem: 'Button' } }
                >
                    { __('Next') }
                    <ChevronIcon />
                </button>
            </div>
        );
    }

    render() {
        const { handleDealersOfferStep } = this.props;

        return (
            <Form
              attr={ {
                  id: DEALERS_OFFER_STEP
              } }
              mix={ { block: 'CheckoutBilling' } }
              onError={ () => {
              } }
              onSubmit={ handleDealersOfferStep }
            >
                <div block="DealersOfferStep" elem="ContentWrapper">
                    { this.renderHeader() }
                    { this.renderModelBlock() }
                    { this.renderProductAttributes() }
                </div>
                { this.renderBottomBlock() }
            </Form>
        );
    }
}

export default DealersOfferStepComponent;
