/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
// import AddToCart from 'Component/AddToCart';
import { lazy } from 'react';

import PRODUCT_TYPE from 'Component/Product/Product.config';
import { COLOR_EXTERIOR } from 'Component/ProductActions/ProductActions.config';
import ProductPrice from 'Component/ProductPrice';
import { GRID_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import {
    Product as SourceProduct
} from 'SourceComponent/Product/Product.component';
// import { lowPriorityLazy } from 'Util/Request/LowPriorityRender';

export const ProductSteps = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product-step" */
    'Component/ProductSteps'
));
export const AddToCart = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "add-to-cart" */
    'Component/AddToCart'
));
export const ProductFilteredConfigurableAttributes = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product-filter-configurable-attribute" */
    'Component/ProductFilteredConfigurableAttributes'
));

/** @namespace Scandipwa/Component/Product/Component */
export class Product extends SourceProduct {
    /**
     * Overridden to remove wishlist button
     */
    renderWishlistButton() {
        return null;
    }

    /**
     * Overridden to add buttons for different steps
     */
    renderAddToCartButton(layout = GRID_LAYOUT) {
        const {
            addToCart,
            inStock,
            quantity,
            getActiveProduct
        } = this.props;

        return (
            <AddToCart
              mix={ { block: this.className, elem: 'AddToCart' } }
              addToCart={ addToCart }
              isDisabled={ !inStock }
              isIconEnabled={ false }
              layout={ layout }
              quantity={ quantity }
              getActiveProduct={ getActiveProduct }
              isPdp
            />
        );
    }

    /*
    * JAID-136 - Overriden to replace ProductConfigurableAttributes with custom ProductSteps component
    * JAID-107 - Changed block name and removed onclick functionality
    */
    renderColorAttribute() {
        const {
            parameters,
            product: { type_id: type, variants = {} },
            inStock,
            device: { isMobile = false }
        } = this.props;

        if (type !== PRODUCT_TYPE.configurable) {
            return null;
        }

        const attributesToRender = [COLOR_EXTERIOR];

        return (
            <div
              block="ProductActions"
              elem="FilteredAttributesWrapper"
            >
                <ProductFilteredConfigurableAttributes
                    // eslint-disable-next-line no-magic-numbers
                  numberOfPlaceholders={ [1, 2] }
                  mix={ { block: this.className, elem: 'Attributes' } }
                  parameters={ parameters }
                  variants={ variants }
                  configurable_options={ this.getConfigurableAttributes() }
                  isContentExpanded
                  inStock={ inStock }
                  isMobile={ isMobile }
                  attributesToRender={ attributesToRender }
                />
            </div>
        );
    }

    /*
    * JAID-136 - Overriden to replace ProductConfigurableAttributes with custom ProductSteps component
    */
    renderConfigurableOptions() {
        const {
            setActiveProduct,
            parameters,
            product: { type_id: type, variants = {} },
            inStock,
            device: { isMobile = false },
            activeProduct,
            areDetailsLoaded,
            useEmptyGallerySwitcher,
            isVariant
        } = this.props;

        if (type !== PRODUCT_TYPE.configurable) {
            return null;
        }

        return (
            <div
              block="ProductActions"
              elem="AttributesWrapper"
            >
                <ProductSteps
                    // eslint-disable-next-line no-magic-numbers
                  numberOfPlaceholders={ [2, 4] }
                  mix={ { block: this.className, elem: 'Attributes' } }
                  parameters={ parameters }
                  variants={ variants }
                  updateConfigurableVariant={ setActiveProduct }
                  activeProduct={ activeProduct }
                  areDetailsLoaded={ areDetailsLoaded }
                  useEmptyGallerySwitcher={ useEmptyGallerySwitcher }
                  isVariant={ isVariant }
                  configurable_options={ this.getConfigurableAttributes() }
                  isContentExpanded
                  inStock={ inStock }
                  isMobile={ isMobile }
                />
            </div>
        );
    }

    // Overridden to add isSchemaRequired & priceRange to props
    renderPrice(isPreview = false) {
        const { getActiveProduct, productPrice, offerCount } = this.props;
        const product = getActiveProduct();

        const {
            type_id: type,
            price_tiers: priceTiers,
            price_range: priceRange
        } = product;

        if (!productPrice) {
            return null;
        }

        return (
            <div
              block={ this.className }
              elem="PriceWrapper"
            >
                <ProductPrice
                  meta
                  price={ productPrice }
                  priceType={ type }
                  tierPrices={ priceTiers }
                  isPreview={ isPreview }
                  mix={ { block: this.className, elem: 'Price' } }
                  variantsCount={ offerCount }
                  priceRange={ priceRange }
                  isSchemaRequired
                />
            </div>
        );
    }
}

export default Product;
