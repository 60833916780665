import {
    BOOKING_SUCCESS,
    CONFIRMATION_REQUIRED
} from 'Component/MyAccountCreateAccount/MyAccountCreateAccount.config';
import MyAccountQuery from 'Query/MyAccount.query';
import {
    CUSTOMER,
    MyAccountDispatcher as SourceMyAccountDispatcher,
    ONE_MONTH_IN_SECONDS
} from 'SourceStore/MyAccount/MyAccount.dispatcher';
import {
    setIsExistingCustomer,
    updateCustomerPasswordResetStatus,
    updateCustomerSignInStatus,
    updateIsLoading
} from 'Store/MyAccount/MyAccount.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import {
    setAuthorizationToken
} from 'Util/Auth';
import { setCartId as setGuestQuoteId } from 'Util/Cart';
import { prepareQuery } from 'Util/Query';
import { executePost, fetchMutation, getErrorMessage } from 'Util/Request';

export {
    CUSTOMER,
    ONE_MONTH_IN_SECONDS
};

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

export const WishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Wishlist/Wishlist.dispatcher'
);

export const ProductCompareDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/ProductCompare/ProductCompare.dispatcher'
);

/** @namespace Scandipwa/Store/MyAccount/Dispatcher */
export class MyAccountDispatcher extends SourceMyAccountDispatcher {
    requestCustomerExists(options = {}, dispatch) {
        const query = MyAccountQuery.getIsEmailAvailable(options);

        return executePost(prepareQuery([query])).then(
            /** @namespace Scandipwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/requestCustomerExists/executePost/then */
            (response) => {
                dispatch(setIsExistingCustomer(!response.isEmailAvailable.is_email_available));
            }
        );
    }

    // Overridden to return reservation response
    createAccount(options = {}, dispatch) {
        const { customer: { email }, password } = options;
        const mutation = MyAccountQuery.getCreateAccountMutation(options);
        dispatch(updateIsLoading(true));

        return fetchMutation(mutation).then(
            /** @namespace Scandipwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/createAccount/fetchMutation/then */
            (data) => {
                const { createCustomer: { customer, bookingResult } } = data;
                const { confirmation_required } = customer;

                if (confirmation_required) {
                    dispatch(updateIsLoading(false));

                    return CONFIRMATION_REQUIRED;
                }

                if (bookingResult) {
                    const { start_time } = bookingResult;

                    if (start_time) {
                        return this.signIn({ email, password }, dispatch).then(
                            /** @namespace Scandipwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/createAccount/fetchMutation/then/signIn/then */
                            () => BOOKING_SUCCESS
                        );
                    }
                }

                return this.signIn({ email, password }, dispatch);
            },

            /** @namespace Scandipwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/createAccount/fetchMutation/then/catch */
            (error) => {
                dispatch(updateIsLoading(false));
                dispatch(showNotification('error', getErrorMessage(error)));
                return false;
            }
        );
    }

    // Overridden to remove merge carts mutation
    async signIn(options = {}, dispatch) {
        dispatch(updateIsLoading(true));

        const mutation = MyAccountQuery.getSignInMutation(options);

        const result = await fetchMutation(mutation);
        const { generateCustomerToken: { token } } = result;

        setAuthorizationToken(token);

        this.handleCartAndWishlistOnSignIn(dispatch);

        await this.requestCustomerData(dispatch);

        dispatch(updateCustomerSignInStatus(true));
        dispatch(updateIsLoading(false));
        dispatch(hideActiveOverlay());
        dispatch(showNotification('success', __('You are successfully logged in!')));

        return true;
    }

    async handleCartAndWishlistOnSignIn(dispatch) {
        ProductCompareDispatcher.then(
            ({ default: dispatcher }) => dispatcher.assignCompareList(dispatch)
        );

        const cartDispatcher = (await CartDispatcher).default;
        // if customer is authorized, `createEmptyCart` mutation returns customer cart token
        const customerCartToken = await cartDispatcher.createGuestEmptyCart(dispatch);

        setGuestQuoteId(customerCartToken);
        cartDispatcher.updateInitialCartData(dispatch);

        WishlistDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch)
        );
    }

    async getOTPSignInValid(options = {}, dispatch) {
        dispatch(updateIsLoading(true));

        const generateLoginOTPCodeMutation = MyAccountQuery.getGenerateLoginOTPCodeMutation(options);

        const result = await fetchMutation(generateLoginOTPCodeMutation);
        dispatch(updateIsLoading(false));

        return result;
    }

    async otpSignIn(options = {}, dispatch) {
        const { proceedDelay } = options;

        dispatch(updateIsLoading(true));

        const generateCustomerTokenOTP = MyAccountQuery.getGenerateCustomerTokenOTPMutation(options);

        const result = await fetchMutation(generateCustomerTokenOTP);
        const {
            generateCustomerTokenOTP: {
                token,
                showPasswordResetOnLogin = false,
                passwordResetToken = '',
                showEmailField = false,
                customerId = 0
            }
        } = result;

        setTimeout(async () => {
            setAuthorizationToken(token);

            this.handleCartAndWishlistOnSignIn(dispatch);

            await this.requestCustomerData(dispatch);

            dispatch(updateCustomerSignInStatus(true));
            dispatch(updateIsLoading(false));
            dispatch(hideActiveOverlay());
            if (showEmailField) {
                dispatch(showNotification('success', __('To see your quotations, you need to Sign Up')));
            } else {
                dispatch(showNotification('success', __('You are successfully logged in!')));
            }
        }, proceedDelay);

        return [true, showPasswordResetOnLogin, passwordResetToken, showEmailField, customerId];
    }

    resetPassword(options = {}, dispatch) {
        const mutation = MyAccountQuery.getResetPasswordMutation(options);

        return fetchMutation(mutation).then(
            /** @namespace Scandipwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/resetPassword/fetchMutation/then/dispatch */
            ({ s_resetPasswordOld: { status } }) => dispatch(updateCustomerPasswordResetStatus(status)),
            /** @namespace Scandipwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/resetPassword/fetchMutation/then/dispatch/catch */
            (errors) => dispatch(updateCustomerPasswordResetStatus('error', getErrorMessage(errors)))
        );
    }
}

export default new MyAccountDispatcher();
