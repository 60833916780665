import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    MyAccountForgotPasswordPopupContainer as JaidahMyAccountForgotPasswordPopupContainer
} from 'JaidahComponent/MyAccountForgotPasswordPopup/MyAccountForgotPasswordPopup.container';

/** @namespace Geely/Component/MyAccountForgotPasswordPopup/Container */
export class MyAccountForgotPasswordPopupContainer extends JaidahMyAccountForgotPasswordPopupContainer {
    updateBreadcrumbs() {
        return null;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountForgotPasswordPopupContainer);
