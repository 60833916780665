import { range } from 'Util/Manipulations';

export const SERVICE_BOOKING_BLOCK_ID = 'service-booking';
export const MILEAGE_INPUT_NAME = 'mileage';
export const TEXT_AREA_MAX_CHARACTERS = 50;
export const MIN_YEAR = 2000;
export const LICENSE_NUMBER_NAME = 'license_number';
export const ONE_HUNDRED = 100;

/** @namespace Scandipwa/Route/ServiceBooking/Config/generateArrayOfYears */
export const generateArrayOfYears = () => range(MIN_YEAR, new Date().getFullYear());

export const MODELS_OPTIONS = [
    'CRETA',
    'TUCSON',
    'ELANTRA',
    'I10',
    'SONATA',
    'ACCENT',
    'VELOS',
    'GETZ',
    'VELOSTER',
    'IONIQ',
    'AZERA',
    'KONA',
    'PALISADE',
    'STARIA',
    'SANTA FE',
    'COUNTY',
    'H13',
    'H16'
];

export const ONLINE_DISCOUNT_DAYS = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday'
];

export const AVAILABLE_TIME = [
    '2:00 PM - 2:30 PM',
    '3:00 PM - 3:30 PM'
];

export const AVAILABLE_DATES = [
    '2022-06-13',
    '2022-06-14',
    '2022-06-15',
    '2022-07-11',
    '2022-07-12',
    '2022-07-13',
    '2022-07-14',
    '2022-07-15',
    '2022-08-11',
    '2022-08-12',
    '2022-08-15'
];

/** @namespace Scandipwa/Route/ServiceBooking/Config/getIndexedDates */
export const getIndexedDates = (timeslots) => (timeslots || []).reduce((acc, { date, starting_time, ending_time }) => {
    if (ending_time) {
        return { ...acc, [date]: [...(acc?.[date] || []), `${starting_time} - ${ending_time}`] };
    }

    return { ...acc, [date]: [...(acc?.[date] || []), starting_time] };
}, {});

export const WAIT_TILL_EMAIL_ENTERED = 500;
export const MIN_EMAIL_LENGTH = 6;

// These are used in the service schedule request to identify which discount type was chosen
export const COUPON_CODE_DISCOUNT = 'TYPED_DISCOUNT';
export const NO_DISCOUNT = 'NO_DISCOUNT';
export const WEEKDAY_BASED_DISCOUNT = 'DEFAULT_DISCOUNT';

export const DISCOUNT_TYPE_OPTIONS = [
    {
        id: COUPON_CODE_DISCOUNT,
        value: COUPON_CODE_DISCOUNT,
        label: __('Coupon code')
    },
    {
        id: WEEKDAY_BASED_DISCOUNT,
        value: WEEKDAY_BASED_DISCOUNT,
        label: __('Online payment discount')
    },
    {
        id: NO_DISCOUNT,
        value: NO_DISCOUNT,
        label: __('No discount')
    }
];

export const FIELD_GROUPS_TITLES = {
    vehicleDetails: () => __('1. Vehicle details'),
    serviceDetails: () => __('2. Service details'),
    paymentType: () => __('3. Payment type'),
    contractDetails: (isPaymentRendered) => __('%s Contact details', isPaymentRendered ? '4.' : '3.')
};

export const COUPON_STATUS_DEFAULT = 'Default';
export const COUPON_STATUS_SUCCESS = 'Success';
export const COUPON_STATUS_ERROR = 'Error';

export const TIME_INFO_MESSAGE_FROM = 13;

export const SERVICE_BOOKING_CONSENT_POPUP_ID = 'service-booking-consent-popup';

export const SERVICE_BOOKING_CONSENT_POPUP_PAYMENT = 'service-booking-popup-payment';
export const SERVICE_BOOKING_CONSENT_POPUP_CONFIRM = 'service-booking-popup-confirm';

export const SERVICE_BOOKING_FORM_NAME = 'service-booking-form';
