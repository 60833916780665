/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
import { PropTypes } from 'prop-types';

import { BOTTOM } from 'Component/ChevronIcon/ChevronIcon.config';
import { ChevronIcon as JaidahChevronIcon } from 'JaidahComponent/ChevronIcon/ChevronIcon.component';

import './ChevronIcon.geely.style.scss';
/** @namespace Geely/Component/ChevronIcon/Component */
export class ChevronIcon extends JaidahChevronIcon {
    static propTypes = {
        ...JaidahChevronIcon.propTypes,
        isGeelyArrow: PropTypes.bool
    };

    static defaultProps = {
        direction: BOTTOM,
        isGeelyArrow: false
    };

    renderGeelyArrow() {
        const { direction } = this.props;

        return (
            <svg block="ChevronArrowIcon" mods={ { direction } } width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5 15.8332L13.3333 9.99984L7.5 4.1665" stroke="white" strokeWidth="2" />
            </svg>
        );
    }

    renderDefaultChevron() {
        const { direction } = this.props;

        return (
            <svg
              block="ChevronIcon"
              mods={ { direction } }
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <g id="shevron">
                    <path id="Vector 6" d="M5.25 11.0833L9.33333 7L5.25 2.91667" stroke="#393839" strokeWidth="1.5" />
                </g>
            </svg>
        );
    }

    render() {
        const { isGeelyArrow } = this.props;
        return isGeelyArrow ? this.renderGeelyArrow() : this.renderDefaultChevron();
    }
}

export default ChevronIcon;
