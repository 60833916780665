/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import DatePicker from 'react-datepicker';

import CalendarIcon from 'Component/CalendarIcon';
import FIELD_TYPE from 'Component/Field/Field.config';
import {
    DatePickerComponent as SourceDatePickerComponent
} from 'SourceComponent/DatePicker/DatePicker.component';

import { DAYS } from './DatePicker.config';

import './DatePicker.override.style';

/** @namespace Scandipwa/Component/DatePicker/Component */
export class DatePickerComponent extends SourceDatePickerComponent {
    months = [
        __('January'),
        __('February'),
        __('March'),
        __('April'),
        __('May'),
        __('June'),
        __('July'),
        __('August'),
        __('September'),
        __('October'),
        __('November'),
        __('December')
    ];

    static propTypes = {
        ...this.propTypes,
        selectedDate: PropTypes.instanceOf(Date),
        fieldType: PropTypes.oneOf([
            FIELD_TYPE.date,
            FIELD_TYPE.dateWithMonthYearSelector
        ])
    };

    static defaultProps = {
        ...this.defaultProps,
        // To have empty placeholder
        selectedDate: null,
        fieldType: FIELD_TYPE.date
    };

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    getCalendarClassName() {
        const { fieldType, isDaysDiscounts } = this.props;
        // eslint-disable-next-line fp/no-let
        let className = 'FieldDate-Calendar';

        const isHeaderWithMonthAndYearSelector = fieldType === FIELD_TYPE.dateWithMonthYearSelector;

        if (isHeaderWithMonthAndYearSelector) {
            className += ' FieldDate-Calendar_withMonthYearSelector';
        }

        if (isDaysDiscounts) {
            className += ' FieldDate-Calendar_withDaysDiscounts';
        }

        return className;
    }

    /**
     * Overridden to add CalendarIcon to input
     */
    renderCustomInput({ value, onClick }, ref) {
        const {
            selectedDate,
            uid,
            setRef,
            placeholder,
            isDisabled
        } = this.props;

        return (
            <label htmlFor={ uid }>
                <input
                  id={ uid }
                  name={ uid }
                  value={ value }
                  type={ FIELD_TYPE.text }
                  onClick={ onClick }
                  ref={ (elem) => {
                      setRef(elem);
                      ref(elem);
                  } }
                  data-date={ selectedDate }
                  placeholder={ placeholder }
                  inputMode="none"
                  readOnly
                  disabled={ isDisabled }
                />
                <CalendarIcon isDisabled={ isDisabled } />
            </label>
        );
    }

    /**
     * Overridden to pass includeDates
     */
    render() {
        const {
            selectedDate,
            handleChange,
            showTimeSelect,
            showTimeSelectOnly,
            maxDate,
            minDate,
            dateFormat,
            timeFormat,
            isClearable,
            availableDates,
            fieldType,
            value,
            filteredDays,
            useValue,
            locale: localeCode,
            isDaysDiscounts,
            children
        } = this.props;

        const isHeaderWithMonthAndYearSelector = fieldType === FIELD_TYPE.dateWithMonthYearSelector;
        // eslint-disable-next-line no-magic-numbers
        const yearDropdownItemNumber = isDaysDiscounts ? 1 : 80;
        const scrollableYearDropdown = isHeaderWithMonthAndYearSelector && !isDaysDiscounts;

        const DateInput = forwardRef(this.renderCustomInput.bind(this));

        const locale = {
            localize: {
                day: (n) => DAYS[localeCode][n],
                month: (n) => this.months[n]
            },
            formatLong: {
                date: () => 'mm/dd/yyyy'
            }
        };

        const filterWorkdays = (date) => {
            if (filteredDays.length) {
                const day = date.getDay();
                return filteredDays.some((arrayDay) => arrayDay === day);
            }

            return true;
        };

        const addDaysClasses = (date) => (isDaysDiscounts ? date.toString().split(' ')[0].toLowerCase() : '');

        return (
            <DatePicker
              locale={ locale }
              selected={ useValue ? value : value || selectedDate }
              onChange={ handleChange }
              showTimeSelect={ showTimeSelect }
              showTimeSelectOnly={ showTimeSelectOnly }
              timeFormat={ timeFormat }
              timeIntervals={ 15 }
              dateFormat={ dateFormat }
              maxDate={ maxDate }
              includeDates={ availableDates }
              isClearable={ isClearable }
              customInput={ <DateInput /> }
              calendarClassName={ this.getCalendarClassName() }
              showPopperArrow={ false }
              // eslint-disable-next-line react/jsx-no-bind
              filterDate={ filterWorkdays }
              minDate={ minDate }
              // eslint-disable-next-line react/jsx-no-bind
              formatWeekDay={ (weekdayName) => weekdayName.substring(0, 1) }
              dateFormatCalendar="LLL yyyy"
              disabledKeyboardNavigation
              showMonthDropdown={ isHeaderWithMonthAndYearSelector }
              showYearDropdown={ isHeaderWithMonthAndYearSelector }
              scrollableYearDropdown={ scrollableYearDropdown }
              scrollableMonthDropdown={ isHeaderWithMonthAndYearSelector }
              yearDropdownItemNumber={ yearDropdownItemNumber }
              dayClassName={ addDaysClasses }
            >
                { children }
            </DatePicker>
        );
    }
}

export default DatePickerComponent;
