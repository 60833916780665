import { ORDER_STATUSES } from 'Util/Orders';

export const SF_ORDER_STATUS_MAP = {
    activeReservation: 'reservation_active',
    approved: 'ihf_approved',
    showroomOnly: 'showroom_only',
    declined: 'ihf_declined',
    canceled: 'canceled',
    denied: 'denied',
    cashOnly: 'cash_only',
    review: 'in_review',
    complete: 'complete',
    completed: 'completed',
    pending: 'pending',
    reserved: 'reserved',
    ready: 'ready_for_booking',
    draft: 'Draft',
    unavailable: 'unavailable'
};

export const NO_CANCEL_BTN_STATUSES = [
    ORDER_STATUSES.ihfApproved,
    ORDER_STATUSES.ihfDeclined,
    ORDER_STATUSES.reserved,
    ORDER_STATUSES.showroomOnly,
    ORDER_STATUSES.review,
    ORDER_STATUSES.unavailable
];
