import { PRODUCT_ATTS_POPUP_ID } from 'Component/ProductAttributes/ProductAttributes.config';
import { SERVICE_BOOKING_BLOCK_ID } from 'Route/ServiceBooking/ServiceBooking.config';

export * from 'JaidahComponent/Header/Header.config';

export const MY_VEHICLES_URL = '/myvehicles';
export const ACCOUNT_DROPDOWN_BREAKPOINT = 200;
export const BODY_MARGIN_REGEX = /\d+/;

export const DEFAULT_NAVIGATION_POPUPS = [PRODUCT_ATTS_POPUP_ID, SERVICE_BOOKING_BLOCK_ID];
