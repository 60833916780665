import { currencyTranslationLut } from 'JaidahUtil/Price/Price';

export * from 'JaidahUtil/Price/Price';

/**
 * Overriden to change minimumFractionDigits
*/
/** @namespace Geely/Util/Price/formatPrice */
export const formatPrice = (price, currency = 'USD', minFractionDigits = 0) => {
    const language = navigator.languages ? navigator.languages[0] : navigator.language;
    const minimumFractionDigits = price === 0 ? 0 : minFractionDigits;

    const priceFormatted = new Intl.NumberFormat(language, {
        style: 'currency', currency, minimumFractionDigits
    }).format(price);

    const translationMap = currencyTranslationLut[document.documentElement.lang];
    const isTranslatable = translationMap && translationMap[currency];

    return isTranslatable ? `${priceFormatted.replace(currency, '')} ${ translationMap[currency] } ` : priceFormatted;
};
