import { getCarFromOrder, getInsuranceFromOrder } from 'Util/Orders/Orders';
import { getIndexedAttributes, getIndexedProducts } from 'Util/Product';

import {
    EVENT_GTM_PRODUCT_REMOVE_FROM_CART,
    EVENT_GTM_PURCHASE
} from '../component/GoogleTagManager/GoogleTagManager.events';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

const onSuccessCancelQuotation = (args, callback, instance) => {
    const { event, orderList: { items: ordersList = [] } = [] } = instance.props;
    const { popupOrderId } = instance.state;
    const { order_list_products = [] } = ordersList.find(({ id }) => id === popupOrderId);
    const order = order_list_products.map(
        (item) => ({
            ...item,
            attributes: getIndexedAttributes(item?.attributes)
        })
    );

    const { insurancePrice } = getInsuranceFromOrder(order);

    const orderWithPrices = order.map((item) => ({
        ...item,
        price: item.is_insurance ? insurancePrice : item?.attributes?.price?.attribute_value
    }));

    event(EVENT_GTM_PRODUCT_REMOVE_FROM_CART, { order: orderWithPrices });

    callback(...args);
};

const handleReservationMessage = (args, _callback, instance) => {
    const [orderId] = args;
    if (orderId) {
        instance.setSuccessReservationMessage(String(orderId));
    } else {
        instance.setFailureReservationMessage();
    }
};

const setSuccessReservationMessage = (args, callback, instance) => {
    const { event, orderList: { items = [] } = {} } = instance.props;
    const [orderId] = args;
    const {
        order_list_products,
        increment_id,
        reservation_payment_data: {
            amount: paymentAmount
        } = {},
        total: {
            grand_total: {
                value: fullPrice
            } = {}
        } = {}
    } = items.find(({ id }) => id === orderId) || {};

    if (order_list_products) {
        const order = getIndexedProducts(order_list_products);

        event(EVENT_GTM_PURCHASE, {
            order,
            baseOrderInfo: { increment_id },
            isMyVehicles: true,
            myVehiclesData: {
                paymentAmount,
                fullPrice
            }
        });
    }

    callback(...args);
};

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData))
    };
};

export default {
    'Scandipwa/Route/MyVehicles/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Scandipwa/Route/MyVehicles/Container': {
        'member-function': {
            onSuccessCancelQuotation,
            setSuccessReservationMessage,
            handleReservationMessage
        }
    }
};
