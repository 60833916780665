import {
    ProductAttributeValueComponent as JaidahProductAttributeValueComponent
} from 'JaidahComponent/ProductAttributeValue/ProductAttributeValue.component';
import { removeColorCode } from 'Util/Product';

import './ProductAttributeValue.geely.style';

/** @namespace Geely/Component/ProductAttributeValue/Component */
export class ProductAttributeValueComponent extends JaidahProductAttributeValueComponent {
    // Added gradient overlay over color options
    renderColorValue(color, label) {
        const { isFormattedAsText, isSelected } = this.props;
        const isLight = this.getIsColorLight(color);

        if (isFormattedAsText) {
            return label || __('N/A');
        }

        const style = {
            '--option-background-color': color,
            '--option-border-color': isLight ? '#dddddd' : color,
            '--option-check-mark-background': isLight ? '#000' : '#fff',
            // stylelint-disable-next-line value-keyword-case
            '--option-is-selected': isSelected ? 1 : 0
        };

        return (
            <>
                <data
                  block="ProductAttributeValue"
                  elem="Color"
                  mods={ { isLight, isSelected } }
                  value={ label }
                  title={ label }
                  style={ style }
                />
                <div block="ProductAttributeValue" elem="ColorGradient" />
            </>
        );
    }

    renderImageValue(img, label) {
        return (
            <>
                { super.renderImageValue(img, label) }
                <div block="ProductAttributeValue" elem="ImageColorOverlay" />
            </>
        );
    }

    renderColorOrImageValue(color, label, isColor) {
        const { isFormattedAsText } = this.props;

        if (isFormattedAsText) {
            return removeColorCode(label) || __('N/A');
        }

        return isColor
            ? this.renderColorValue(color, label)
            : this.renderImageValue(color, label);
    }
}

export default ProductAttributeValueComponent;
