/* eslint-disable fp/no-let */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import CheckmarkIcon from 'Component/CheckmarkIcon/CheckmarkIcon.component';
import { FieldComponent as SourceField } from 'JaidahComponent/Field/Field.component';
import { noopFn } from 'Util/Common';

import { FIELD_TYPE, NON_REGULAR_TYPES } from './Field.geely.config';

import './Field.geely.style';

/** @namespace Geely/Component/Field/Component */
export class FieldComponent extends SourceField {
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    componentDidUpdate(prevProps, _prevState) {
        const {
            fieldRef,
            attr: { defaultValue } = {}
        } = this.props;

        const {
            attr: { defaultValue: prevDefaultValue } = {}
        } = prevProps;

        if (fieldRef && fieldRef.value === prevDefaultValue && prevDefaultValue !== defaultValue) {
            fieldRef.value = defaultValue;
        }
    }

    renderCheckmark() {
        return (
            <svg
              block="RadioIcon"
              width="14"
              height="10"
              viewBox="0 0 14 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path fillRule="evenodd" clipRule="evenodd" d="M11.8335 0.179291L13.0825 1.42833L4.92265 9.58821L0.929443 5.595L2.17849 4.34596L4.92265 7.09012L11.8335 0.179291Z" fill="white" />
            </svg>
        );
    }

    renderDot() {
        return (
            <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="4" cy="4" r="4" fill="white" />
            </svg>

        );
    }

    renderCheckboxOrRadio() {
        const {
            type,
            setRef,
            attr,
            attr: { id = '' } = {},
            events: { onChange },
            events,
            isDisabled,
            label,
            isExactLabel,
            addRequiredTag
        } = this.props;

        const elem = type.charAt(0).toUpperCase() + type.slice(1);
        const inputEvents = {
            ...events,
            onChange: onChange || noopFn
        };
        const resultLabel = isExactLabel ? label : <span>{ label }</span>;
        const requiredTag = addRequiredTag ? this.renderRequiredTag() : null;

        return (
            <label htmlFor={ id } block="Field" elem={ `${elem}Label` }>
                <input
                  ref={ (elem) => setRef(elem) }
                  disabled={ isDisabled }
                  type={ type }
                  { ...attr }
                  { ...inputEvents }
                />
                <div block="input-control">
                    { type === FIELD_TYPE.checkbox && this.renderCheckmark() }
                    { type === FIELD_TYPE.radio && this.renderDot() }
                </div>
                { resultLabel }
                { requiredTag }
            </label>
        );
    }

    /**
     * Overridden to add checkmark on input validation
     */
    renderDefaultInput() {
        const {
            type, setRef, attr, events, isDisabled, validationResponse, handleKeyPressDown, handleBlur
        } = this.props;

        return (
            <>
                    <input
                      ref={ (elem) => setRef(elem) }
                      disabled={ isDisabled }
                      type={ type }
                        // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                      { ...attr }
                        // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                      { ...events }
                      onKeyDown={ handleKeyPressDown }
                      onBlur={ handleBlur }
                    />
                    { validationResponse === true && !isDisabled && <CheckmarkIcon /> }
            </>
        );
    }

    render() {
        const {
            validationResponse,
            mix,
            isPasswordToggle,
            labelLink
        } = this.props;
        let { type } = this.props;
        const inputRenderer = this.renderMap[type];
        const isPasswordToggleVisible = isPasswordToggle
            && (validationResponse === true || validationResponse === null);

        if (type === FIELD_TYPE.terms || type === FIELD_TYPE.send) {
            type = FIELD_TYPE.checkbox;
        }

        const isValid = validationResponse === true;

        const isRegularType = !NON_REGULAR_TYPES.includes(type);

        return (
            <div block="Field" elem="Wrapper" mods={ { type } } mix={ mix }>
                <div
                  block="Field"
                  mods={ {
                      type,
                      isValid,
                      hasError: validationResponse !== true && Object.keys(validationResponse || {}).length !== 0
                  } }
                  mix={ mix }
                >
                    { isRegularType
                        && this.renderLabel() }
                    { inputRenderer && inputRenderer() }
                    { isPasswordToggleVisible && this.renderShowPasswordButton() }
                    { labelLink }
                </div>
                { this.renderErrorMessages() }
                { type !== FIELD_TYPE.file && this.renderSubLabel() }
            </div>
        );
    }
}
export default FieldComponent;
