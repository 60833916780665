import { connect } from 'react-redux';

import { MY_VEHICLES_URL } from 'Component/Header/Header.config';
import {
    mapDispatchToProps,
    mapStateToProps,
    MyAccountSignInContainer as JaidahMyAccountSignInContainer
} from 'JaidahComponent/MyAccountSignIn/MyAccountSignIn.container';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

export {
    mapStateToProps,
    mapDispatchToProps
};

/** @namespace Geely/Component/MyAccountSignIn/Container */
export class MyAccountSignInContainer extends JaidahMyAccountSignInContainer {
    async onSuccessSubmitAfterOPT(showPasswordResetOnLogin, passwordResetToken, showEmailField, customerId) {
        const {
            onSignIn,
            isPdp
        } = this.props;
        const { state } = history?.location;

        const isRedirectedToPasswordResetFromPdp = showPasswordResetOnLogin && !!passwordResetToken && !!isPdp;
        await onSignIn(isRedirectedToPasswordResetFromPdp);
        if (state?.isMyVehicle && !showPasswordResetOnLogin) {
            history.push(appendWithStoreCode(state.prevLocation));
        }

        if (showPasswordResetOnLogin && passwordResetToken) {
            history.push(
                // eslint-disable-next-line max-len
                appendWithStoreCode(`/customer/account/createPassword?token=${passwordResetToken}&id=${customerId}${showEmailField ? '&email=true' : ''}`),
                { pathToRedirect: isPdp ? appendWithStoreCode(MY_VEHICLES_URL) : '' }
            );
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountSignInContainer);
