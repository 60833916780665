import {
    DISPLACEMENT, ENGINE, FUEL, MAX_POWER, MAX_TORQUE, TR_TYPE, WHEEL
} from 'Component/ProductActions/ProductActions.config';

export * from 'JaidahComponent/ProductAttributes/ProductAttributes.config';

export const VEHICLE_ATTR = [
    ENGINE,
    DISPLACEMENT,
    MAX_POWER,
    MAX_TORQUE,
    TR_TYPE,
    FUEL,
    WHEEL
];

export const DETAILS_GROUP = 'details';
export const COLORS_GROUP = 'colors';
export const UPGRADES_GROUP = 'upgrades';

export const ACCESSORIES = 'accessories';
export const SERVICE = 'service_packages';
export const INSURANCE = 'insurance';
