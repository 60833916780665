import {
    lazy,
    useEffect, useState
} from 'react';

import { EV_PRIORITY_LOAD_END, waitForPriorityLoad } from './LowPriorityLoad';

/** @namespace Scandipwa/Util/Request/LowPriorityRender/lowPriorityLazy */
export const lowPriorityLazy = (callback) => lazy(async () => {
    await waitForPriorityLoad();

    const ev = new Event(EV_PRIORITY_LOAD_END);
    document.dispatchEvent(ev);

    return callback();
});

/** @namespace Scandipwa/Util/Request/LowPriorityRender/AfterPriority */

export function AfterPriority({ children, fallback = null }) {
    const [isPriorityLoaded, setIsPriorityLoaded] = useState(window.isPriorityLoaded);

    function onPriorityLoad() {
        setIsPriorityLoaded(true);
    }

    useEffect(() => {
        document.addEventListener(EV_PRIORITY_LOAD_END, onPriorityLoad, { once: true });

        return () => {
            document.removeEventListener(EV_PRIORITY_LOAD_END, onPriorityLoad);
        };
    }, []);

    if (!isPriorityLoaded && fallback) {
        return fallback;
    }

    return children;
}
