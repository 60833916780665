// import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './CompareIcon.style';

/** @namespace Geely/Component/CompareIcon/Component */
export class CompareIconComponent extends PureComponent {
    render() {
        return (
            <svg
              block="CompareIcon"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
                <path d="M3.75 5.4165H16.6667" strokeWidth="1.5" />
                <path d="M16.25 14.5835L3.33333 14.5835" strokeWidth="1.5" />
                <path d="M13.3335 1.6665L17.0835 5.4165L13.3335 9.1665" strokeWidth="1.5" />
                <path d="M6.6665 18.3335L2.9165 14.5835L6.6665 10.8335" strokeWidth="1.5" />
            </svg>
        );
    }
}

export default CompareIconComponent;
