import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    ProductCardContainer as JaidahProductCardContainer
} from 'JaidahComponent/ProductCard/ProductCard.container';
import { noopFn } from 'Util/Common';

export * from 'JaidahComponent/ProductCard/ProductCard.container';

/** @namespace Geely/Component/ProductCard/Container */
export class ProductCardContainer extends JaidahProductCardContainer {
    static PropTypes = {
        ...this.PropTypes,
        onLoad: PropTypes.func
    };

    static defaultProps = {
        ...this.defaultProps,
        onLoad: noopFn
    };

    containerProps() {
        const { onLoad } = this.props;

        return {
            ...super.containerProps(),
            onLoad
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardContainer);
