/* eslint-disable no-magic-numbers */
import { DEFAULT_INTEREST_RATE } from 'Component/PaymentStep/PaymentStep.config.js';
import getStore from 'Util/Store';

export {
    calculateFinalPrice, formatCurrency, roundPrice, getLowestPriceTiersPrice, calculateTierDiscountOverSpecialPrice
} from 'SourceUtil/Price/Price';

export const currencyArTranslation = {
    QAR: 'ريال قطري'
};

export const currencyTranslationLut = {
    ar: currencyArTranslation
};

/** @namespace Scandipwa/Util/Price/translateFormattedPrice */
export const translateFormattedPrice = (formattedPrice) => {
    const { lang } = document.documentElement;
    const lut = currencyTranslationLut[lang];

    if (!lut) {
        return formattedPrice;
    }

    const translatedFormattedPriceSplitted = formattedPrice.split(/\s/).map((part) => lut[part] || part);
    const isTranslated = translatedFormattedPriceSplitted.some((part) => (part !== lut[part]));

    if (document.dir === 'rtl' && isTranslated) {
        translatedFormattedPriceSplitted.reverse();
    }

    return translatedFormattedPriceSplitted.join(' ');
};

/**
 * Overriden to include currencyDisplay option
 */
/** @namespace Scandipwa/Util/Price/formatPrice */
export const formatPrice = (price, currency = 'USD') => {
    const language = navigator.languages ? navigator.languages[0] : navigator.language;

    const priceFormatted = new Intl.NumberFormat(language, {
        style: 'currency', currency, minimumFractionDigits: 0
    }).format(price);

    const translationMap = currencyTranslationLut[document.documentElement.lang];
    const isTranslatable = translationMap && translationMap[currency];

    return isTranslatable ? `${priceFormatted.replace(currency, '')} ${ translationMap[currency] } ` : priceFormatted;
};

/**
 * Added calculation for price per month
 */
/** @namespace Scandipwa/Util/Price/calculatePricePerMonth */
export const calculatePricePerMonth = (price, numberOfMonths, interestRateInDecimals, downPaymentPercent) => {
    const downPaymentInDecimals = downPaymentPercent / 100;

    const interestRate = interestRateInDecimals ?? DEFAULT_INTEREST_RATE / 100;

    const numberOfYears = numberOfMonths / 12;

    const priceAfterDP = price * (1 - downPaymentInDecimals);

    const {
        ConfigReducer: { ihf_rate = interestRate } = {}
    } = getStore().getState() || {};

    const pricePerMonth = (priceAfterDP + (priceAfterDP * ihf_rate * numberOfYears)) / numberOfMonths;
    return Math.round(pricePerMonth);
};

/** @namespace Scandipwa/Util/Price/numberFromFormatedPrice */
export const numberFromFormatedPrice = (formattedPrice) => parseInt(formattedPrice.replace(/[^0-9]/g, ''), 10);
