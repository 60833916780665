/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
import ExpandableContent from 'Component/ExpandableContent';

export const renderPriceSwatch = (args, callback, instance) => {
    const {
        attribute_code,
        isContentExpanded
    } = instance.props;

    return (
        <ExpandableContent
          key={ attribute_code }
          heading={ __('Price') }
          isArrow
          renderIcon
          mix={ {
              block: 'ProductConfigurableAttributes',
              elem: 'Wrapper'
          } }
          isContentExpanded={ isContentExpanded }
        >
            { callback(...args) }
        </ExpandableContent>
    );
};

export default {
    'Component/CategoryConfigurableAttributes/Component': {
        'member-function': {
            renderPriceSwatch: {
                implementation: renderPriceSwatch,
                position: 110
            }
        }
    }
};
