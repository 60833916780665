import {
    AVAILABLE_DATES,
    AVAILABLE_TIME,
    generateArrayOfYears,
    getIndexedDates,
    MILEAGE_INPUT_NAME,
    MIN_EMAIL_LENGTH,
    MIN_YEAR,
    SERVICE_BOOKING_BLOCK_ID,
    TEXT_AREA_MAX_CHARACTERS,
    WAIT_TILL_EMAIL_ENTERED
} from 'JaidahRoute/ServiceBooking/ServiceBooking.config';

export {
    SERVICE_BOOKING_BLOCK_ID,
    MILEAGE_INPUT_NAME,
    TEXT_AREA_MAX_CHARACTERS,
    MIN_YEAR,
    generateArrayOfYears,
    AVAILABLE_TIME,
    AVAILABLE_DATES,
    getIndexedDates,
    WAIT_TILL_EMAIL_ENTERED,
    MIN_EMAIL_LENGTH
};

export const MODELS_OPTIONS = [
    'AVEO',
    'GROOVE',
    'CAPTIVA',
    'BLAZER',
    'TRAVERSE',
    'SUBURBAN',
    'TAHOE',
    'SILVERADO',
    'CAMARO',
    'CORVETTE',
    'MALIBU',
    'EQUINOX',
    'LUMINA',
    'VAN',
    'EPICA',
    'IMPALA',
    'TRAILBLAZER',
    'SPARK',
    'TRAX',
    'BOLTEV'
];

export * from 'JaidahRoute/ServiceBooking/ServiceBooking.config';
