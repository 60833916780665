import {
    ProductListQuery as JaidahProductListQuery
} from 'JaidahQuery/ProductList.query';
import { isMobile } from 'Util/Mobile';

/** @namespace Geely/Query/ProductList/Query */
export class ProductListQuery extends JaidahProductListQuery {
    _getProductInterfaceFields(isVariant, isForLinkedProducts = false, isForWishlist = false) {
        const { isPlp = false, isLinkedProducts = false } = this.options;

        const fields = super._getProductInterfaceFields(isVariant, isForLinkedProducts, isForWishlist);

        // Allows to display second image on hover and for linked products
        if ((!isMobile?.any() && isPlp && !isVariant) || isLinkedProducts) {
            fields.push(
                this._getMediaGalleryField()
            );
        }

        return fields;
    }

    _getProductFields() {
        const { isLinkedProducts = false } = this.options;

        // requests filters for linked products
        if (isLinkedProducts) {
            return [
                this._getItemsField(),
                this._getAggregationsField()
            ];
        }

        return super._getProductFields();
    }

    _getARPFields() {
        const arpFields = super._getARPFields();

        arpFields.push('related_products_type');

        return arpFields;
    }
}

export default new ProductListQuery();
