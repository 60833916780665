/* eslint-disable max-len */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable consistent-return */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import parser from 'html-react-parser';
import attributesToProps from 'html-react-parser/lib/attributes-to-props';
import { lazy, Suspense } from 'react';

import Image from 'Component/Image';
import Loader from 'Component/Loader/Loader.component';
import {
    HtmlComponent as JaidahHtmlComponent
} from 'JaidahComponent/Html/Html.component';
import { setLoadedFlag } from 'Util/Request/LowPriorityLoad';
import { AfterPriority } from 'Util/Request/LowPriorityRender';

export const WidgetFactory = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "widget" */
    'Component/WidgetFactory'
));

export const HIGH_PRIORITY_ELEMENTS = ['widget', 'img'];

/** @namespace Geely/Component/Html/Component */
export class HtmlComponent extends JaidahHtmlComponent {
    isPriorityLoading = false;

    lastBlock = null;

    replaceImages({ attribs }) {
        const attributes = attributesToProps(attribs);

        if (attribs.src) {
            return <Image { ...attributes } isPlain onImageLoad={ setLoadedFlag } />;
        }
    }

    parserOptions = {
        replace: (domNode) => {
            const {
                data,
                name: domName,
                attribs: domAttrs,
                parent,
                next
            } = domNode;

            // if no images or widgets when the last element is rendered we set the priority loading flag to false
            if (!parent && !next && !this.isPriorityLoading) {
                setLoadedFlag();
            }

            if (data && !data.replace(/\u21b5/g, '').replace(/\s/g, '').length) {
                return <></>;
            }

            const rule = this.rules.find((rule) => {
                const { query: { name, attribs } } = rule;

                if (name && domName && name.indexOf(domName) !== -1) {
                    return true;
                }

                if (attribs && domAttrs) {
                    // eslint-disable-next-line fp/no-loops, fp/no-let
                    for (let i = 0; i < attribs.length; i++) {
                        const attrib = attribs[i];

                        if (typeof attrib === 'object') {
                            const queryAttrib = Object.keys(attrib)[0];

                            if (Object.prototype.hasOwnProperty.call(domAttrs, queryAttrib)) {
                                return domAttrs[queryAttrib].match(Object.values(attrib)[0]);
                            }
                        } else if (Object.prototype.hasOwnProperty.call(domAttrs, attrib)) {
                            return true;
                        }
                    }
                }

                return false;
            });

            if (rule) {
                const { replace } = rule;

                if (this.isPriorityLoading) {
                    return (
                        <AfterPriority>
                            { replace.call(this, domNode) }
                        </AfterPriority>
                    );
                }

                if (rule?.query?.name?.some((name) => HIGH_PRIORITY_ELEMENTS.includes(name)) && !this.isPriorityLoading) {
                    this.isPriorityLoading = true;
                }

                return replace.call(this, domNode);
            }
        }
    };

    replaceWidget({ attribs }) {
        return (
            <Suspense fallback={ <Loader isLoading /> }>
                <WidgetFactory { ...this.attributesToProps(attribs) } />
            </Suspense>
        );
    }

    getLastRenderElement(lastChildren) {
        const { children = [] } = lastChildren;

        if (children.length) {
            return this.getLastRenderElement(children[children.length - 1]);
        }

        return lastChildren;
    }

    render() {
        const { content } = this.props;

        return parser(content, this.parserOptions);
    }
}

export default HtmlComponent;
