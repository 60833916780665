import Image from 'Component/Image';
import PRODUCT_TYPE from 'Component/Product/Product.config';
import ProductPrice from 'Component/ProductPrice';
import TextPlaceholder from 'Component/TextPlaceholder';
import {
    ProductCardComponent as JaidahProductCardComponent
} from 'JaidahComponent/ProductCard/ProductCard.component';

import './ProductCard.geely.style';

/** @namespace Geely/Component/ProductCard/Component */
export class ProductCardComponent extends JaidahProductCardComponent {
    renderPrice() {
        const {
            getActiveProduct,
            product: {
                type_id: baseType
            } = {},
            productPrice
        } = this.props;

        const {
            price_range: priceRange,
            type_id: typeId,
            price_tiers: priceTiers
        } = getActiveProduct();

        if (!priceRange) {
            return this.renderTextPlaceholder();
        }

        // If product is not a variant.
        const notConfigured = baseType !== PRODUCT_TYPE.configurable || typeId === baseType;

        if (!productPrice) {
            return null;
        }

        return (
            <div
              block={ this.className }
              elem="PriceWrapper"
            >
                <ProductPrice
                  meta
                  price={ productPrice }
                  priceType={ typeId }
                  tierPrices={ priceTiers }
                  isPreview={ notConfigured }
                  isPlp
                  mix={ { block: this.className, elem: 'Price' } }
                />
            </div>
        );
    }

    renderSecondImage(image, mix) {
        const { device: { isMobile = true } } = this.props;

        if (isMobile || !image) {
            return null;
        }

        const { base: { url } = {} } = image;

        if (!url) {
            return null;
        }

        const secondPictureMix = {
            ...mix,
            mix: {
                block: 'ProductCard',
                elem: 'SecondPicture'
            }
        };

        return (
            <Image
              src={ url }
              ratio="custom"
              mix={ {
                  block: 'ProductCard',
                  elem: 'Picture',
                  mix: secondPictureMix
              } }
            />
        );
    }

    renderAddToCart() {
        const {
            layout,
            inStock,
            product
        } = this.props;
        const isPlaceholder = Object.keys(product).length === 0;

        if (!inStock) {
            return (
                <button
                  block="AddToCart"
                  mods={ { layout } }
                  mix={ { block: 'Button' } }
                >
                    { __('View') }
                </button>
            );
        }

        return (
            <button
              block="AddToCart"
              mods={ { layout } }
              mix={ { block: 'Button' } }
              disabled={ isPlaceholder }
            >
                { __('Select') }
            </button>
        );
    }

    renderCardContent() {
        const { renderContent } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            this.renderCardLinkWrapper((
                <>
                    <div block="ProductCard" elem="FigureReview">
                        <figure block="ProductCard" elem="Figure">
                            { this.renderPicture() }
                        </figure>
                    </div>
                    <div block="ProductCard" elem="Content">
                        { this.renderReviews() }
                        <div block="ProductCard" elem="Info">
                            { this.renderCustomName() }
                            { this.renderShortDescription() }
                        </div>
                        <div block="ProductCard" elem="BottomBlock">
                            { this.renderPrice() }
                            { this.renderAddToCart() }
                        </div>
                    </div>
                </>
            ))
        );
    }

    renderCustomName() {
        const { product: { name } } = this.props;

        return (
            <h3 block={ this.className } elem="Name">
                <TextPlaceholder content={ name } />
            </h3>
        );
    }

    renderShortDescription() {
        return null;
    }

    renderPicture(mix = {}) {
        const {
            product: {
                id,
                name,
                media_gallery_entries = [],
                image: { url } = {}
            },
            thumbnail,
            device: { isMobile = true } = {},
            onLoad
        } = this.props;

        const secondImage = media_gallery_entries[1];

        this.sharedComponent = (
            <Image
              imageRef={ this.imageRef }
              src={ url }
              alt={ name }
              ratio={ isMobile ? '16x9' : 'custom' }
              mix={ { block: 'ProductCard', elem: 'Picture', mix } }
              isPlaceholder={ !id }
              onImageLoad={ onLoad }
            />
        );

        return (
            <div
              block="ProductCard"
              elem="ImageBlock"
              mods={ { isMultipleImages: !isMobile && !!secondImage } }
            >
                { this.sharedComponent }
                { this.renderSecondImage(secondImage) }
                <img
                  style={ { display: 'none' } }
                  alt={ name }
                  src={ thumbnail }
                />
            </div>
        );
    }
}

export default ProductCardComponent;
