import {
    UPDATE_CUSTOMER_DETAILS,
    UPDATE_CUSTOMER_IS_LOADING,
    UPDATE_CUSTOMER_IS_LOCKED,
    UPDATE_CUSTOMER_PASSWORD_FORGOT_EMAIL,
    UPDATE_CUSTOMER_PASSWORD_FORGOT_STATUS,
    UPDATE_CUSTOMER_PASSWORD_RESET_STATUS,
    UPDATE_CUSTOMER_SIGN_IN_STATUS,
    updateCustomerDetails,
    updateCustomerPasswordForgotEmail,
    updateCustomerPasswordForgotStatus,
    updateCustomerPasswordResetStatus,
    updateCustomerSignInStatus,
    updateIsLoading,
    updateIsLocked
} from 'SourceStore/MyAccount/MyAccount.action';

export {
    UPDATE_CUSTOMER_DETAILS,
    UPDATE_CUSTOMER_SIGN_IN_STATUS,
    UPDATE_CUSTOMER_PASSWORD_RESET_STATUS,
    UPDATE_CUSTOMER_PASSWORD_FORGOT_STATUS,
    UPDATE_CUSTOMER_IS_LOADING,
    UPDATE_CUSTOMER_IS_LOCKED,
    UPDATE_CUSTOMER_PASSWORD_FORGOT_EMAIL,
    updateCustomerSignInStatus,
    updateCustomerDetails,
    updateCustomerPasswordResetStatus,
    updateCustomerPasswordForgotStatus,
    updateIsLoading,
    updateIsLocked,
    updateCustomerPasswordForgotEmail
};

export const UPDATE_CUSTOMER_EXISTS = 'UPDATE_CUSTOMER_EXISTS';

/** @namespace Scandipwa/Store/MyAccount/Action/setIsExistingCustomer */
export const setIsExistingCustomer = (isExistingCustomer) => ({
    type: UPDATE_CUSTOMER_EXISTS,
    isExistingCustomer
});
