Mosaic.setPlugins([require('./plugin/BaseProductData.plugin.js'),
require('./plugin/CategoryConfigurableAttributesComponent.plugin.js'),
require('./plugin/GeneralData.plugin.js'),
require('./plugin/MyAccountContainer.plugin.js'),
require('./plugin/PriceSliderComponent.plugin.js'),
require('./plugin/ProductLabelComponent.plugin.js'),
require('./plugin/ProductStepsComponent.plugin.js'),
require('./plugin/ResetAttributesContainer.plugin.js'),
require('./../../scandipwa/packages/@scandiweb/mageworx-seo/src/plugin/CategoryDetailsComponent.plugin.js'),
require('./../../scandipwa/packages/@scandiweb/mageworx-seo/src/plugin/CategoryPageContainer.plugin.js'),
require('./../../scandipwa/packages/@scandiweb/mageworx-seo/src/plugin/CategoryQuery.plugin.js'),
require('./../../scandipwa/packages/@scandiweb/mageworx-seo/src/plugin/CmsPageContainer.plugin.js'),
require('./../../scandipwa/packages/@scandiweb/mageworx-seo/src/plugin/CmsPageQuery.plugin.js'),
require('./../../scandipwa/packages/@scandiweb/mageworx-seo/src/plugin/ConfigQuery.plugin.js'),
require('./../../scandipwa/packages/@scandiweb/mageworx-seo/src/plugin/HtmlSitemapReducer.plugin.js'),
require('./../../scandipwa/packages/@scandiweb/mageworx-seo/src/plugin/ProductActionsComponent.plugin.js'),
require('./../../scandipwa/packages/@scandiweb/mageworx-seo/src/plugin/ProductPageContainer.plugin.js'),
require('./../../scandipwa/packages/@scandiweb/mageworx-seo/src/plugin/ProductQuery.plugin.js'),
require('./../../scandipwa/packages/@scandiweb/mageworx-seo/src/plugin/Router.plugin.js'),
require('./../../scandipwa/packages/@scandiweb/mageworx-seo/src/plugin/RouterContainer.plugin.js'),
require('./../../scandipwa/packages/webpack-i18n-runtime/src/plugin/App.plugin.js'),
require('./../../scandipwa/packages/@scandiweb/price-slider/src/plugin/CategoryConfigurableAttributesComponent.plugin.js'),
require('./../../scandipwa/packages/@scandiweb/price-slider/src/plugin/CategoryFilterOverlayContainer.plugin.js'),
require('./../../scandipwa/packages/@scandiweb/price-slider/src/plugin/ProductListInfoReducer.plugin.js'),
require('./../../scandipwa/packages/@scandiweb/price-slider/src/plugin/ProductListQuery.plugin.js'),
require('./../../scandipwa/packages/@scandiweb/price-slider/src/plugin/ResetAttributesContainer.plugin.js'),
require('./../../scandipwa/packages/catalog-load-more/src/plugin/ProductListComponent.plugin.js'),
require('./../../scandipwa/packages/catalog-load-more/src/plugin/ProductListContainer.plugin.js'),
require('./../../scandipwa/packages/catalog-load-more/src/plugin/ProductListPageComponent.plugin.js'),
require('./../../scandipwa/packages/catalog-load-more/src/plugin/ProductListReducer.plugin.js'),
require('./../../scandipwa/packages/adobe-page-builder/src/plugin/config/ConfigDispatcher.plugin.js'),
require('./../../scandipwa/packages/adobe-page-builder/src/plugin/config/ConfigReducer.plugin.js'),
require('./../../scandipwa/packages/adobe-page-builder/src/plugin/content-type/Buttons.plugin.js'),
require('./../../scandipwa/packages/adobe-page-builder/src/plugin/content-type/HtmlCode.plugin.js'),
require('./../../scandipwa/packages/adobe-page-builder/src/plugin/content-type/Map.plugin.js'),
require('./../../scandipwa/packages/adobe-page-builder/src/plugin/content-type/Slider.plugin.js'),
require('./../../scandipwa/packages/adobe-page-builder/src/plugin/content-type/Tab.plugin.js'),
require('./../../scandipwa/packages/adobe-page-builder/src/plugin/html/HtmlComponent.plugin.js'),
require('./../../scandipwa/packages/StoreFinder/src/plugin/HeaderComponent.plugin.js'),
require('./../../scandipwa/packages/StoreFinder/src/plugin/Router.plugin.js'),
require('./../../scandipwa/packages/StoreFinder/src/plugin/StoreIndex.plugin.js'),
require('./../../scandipwa/packages/@scandiweb/flexibleforms/src/plugin/StoreReducer.plugin.js'),
require('./../../scandipwa/packages/scandipwa-amasty-labels/src/plugin/ProductActionsPlugins/ProductActionsComponent.plugin.js'),
require('./../../scandipwa/packages/scandipwa-amasty-labels/src/plugin/ProductCardPlugins/ProductCardComponent.plugin.js'),
require('./../../scandipwa/packages/scandipwa-amasty-labels/src/plugin/ProductGalleryPlugins/ProductGalleryComponent.plugin.js'),
require('./../../scandipwa/packages/scandipwa-amasty-labels/src/plugin/ProductListPlugins/ProductListMSTP.plugin.js'),
require('./../../scandipwa/packages/scandipwa-amasty-labels/src/plugin/ProductListQuery.plugin.js'),
require('./../../scandipwa/packages/scandipwa-amasty-labels/src/plugin/ProductStepsPlugins/ProductStepsComponent.plugin.js'),
require('./../../scandipwa/packages/scandipwa-amasty-labels/src/plugin/RouterContainerMDTP.plugin.js'),
require('./../../scandipwa/packages/scandipwa-amasty-labels/src/plugin/StoreReducer.plugin.js'),
require('./../../scandipwa/packages/gtm-v5/src/plugin/CartItemChangeQuantity.plugin.js'),
require('./../../scandipwa/packages/gtm-v5/src/plugin/CheckoutBillingContainer.plugin.js'),
require('./../../scandipwa/packages/gtm-v5/src/plugin/CheckoutPurchasePrepare.plugin.js'),
require('./../../scandipwa/packages/gtm-v5/src/plugin/CheckoutShippingContainer.plugin.js'),
require('./../../scandipwa/packages/gtm-v5/src/plugin/ConfigQuery.plugin.js'),
require('./../../scandipwa/packages/gtm-v5/src/plugin/DeliveryStepContainer.plugin.js'),
require('./../../scandipwa/packages/gtm-v5/src/plugin/HandleCustomerDataOnInit.plugin.js'),
require('./../../scandipwa/packages/gtm-v5/src/plugin/MenuItemContainer.plugin.js'),
require('./../../scandipwa/packages/gtm-v5/src/plugin/MyAccountQuery.plugin.js'),
require('./../../scandipwa/packages/gtm-v5/src/plugin/MyVehiclesContainer.plugin.js'),
require('./../../scandipwa/packages/gtm-v5/src/plugin/NoMatch.plugin.js'),
require('./../../scandipwa/packages/gtm-v5/src/plugin/OrderReservationContainer.plugin.js'),
require('./../../scandipwa/packages/gtm-v5/src/plugin/PaymentStepContainer.plugin.js'),
require('./../../scandipwa/packages/gtm-v5/src/plugin/ProductClick.plugin.js'),
require('./../../scandipwa/packages/gtm-v5/src/plugin/ProductContainer.plugin.js'),
require('./../../scandipwa/packages/gtm-v5/src/plugin/ProductLinks.plugin.js'),
require('./../../scandipwa/packages/gtm-v5/src/plugin/ProductList.plugin.js'),
require('./../../scandipwa/packages/gtm-v5/src/plugin/ProductListPageComponent.plugin.js'),
require('./../../scandipwa/packages/gtm-v5/src/plugin/ProductListQuery.plugin.js'),
require('./../../scandipwa/packages/gtm-v5/src/plugin/ProductPageContainer.plugin.js'),
require('./../../scandipwa/packages/gtm-v5/src/plugin/RelatedProductCard.plugin.js'),
require('./../../scandipwa/packages/gtm-v5/src/plugin/RouterComponentPlugin.plugin.js'),
require('./../../scandipwa/packages/gtm-v5/src/plugin/RouterContainer.plugin.js'),
require('./../../scandipwa/packages/gtm-v5/src/plugin/RouterContainerMDTP.plugin.js'),
require('./../../scandipwa/packages/gtm-v5/src/plugin/SearchFieldComponent.plugin.js'),
require('./../../scandipwa/packages/gtm-v5/src/plugin/SearchItemContainer.plugin.js'),
require('./../../scandipwa/packages/gtm-v5/src/plugin/SearchOverlayComponent.plugin.js'),
require('./../../scandipwa/packages/gtm-v5/src/plugin/ServiceBookingContainer.plugin.js'),
require('./../../scandipwa/packages/gtm-v5/src/plugin/ShowroomBookingContainer.plugin.js'),
require('./../../scandipwa/packages/gtm-v5/src/plugin/StoreReducer.plugin.js'),
require('./../../scandipwa/packages/gtm-v5/src/plugin/WishlistItemComponent.plugin.js'),
require('./../../scandipwa/packages/gtm-v5/src/plugin/addToCart.plugin.js'),
require('./../../scandipwa/packages/gtm-v5/src/plugin/myAccountLoginRegister.plugin.js'),
require('./../../scandipwa/packages/gtm-v5/src/plugin/wishlistItemAddToCart.plugin.js')]);
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { render } from 'react-dom';

import App from 'Component/App';

import 'Util/Polyfill';
import 'Style/main';

export const isPriorityLoadedByDefault = (
    window.actionName?.type !== 'CATEGORY'
    && window.actionName?.type !== 'PRODUCT'
    // checks if current page is homepage as its type is 'PWA_ROUTER' by default as there is a route for it
    && !/^(http|https):\/{2}([^/])+\/?(eng|ar)?\/?$/.test(window.location.href)
);

// vvv Set to false, aka. make app wait for priority load ONLY for given pages
window.isPriorityLoaded = isPriorityLoadedByDefault;
// ^^^ end of added section

// let's register service-worker
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        const swUrl = '/service-worker.js';

        navigator.serviceWorker.register(swUrl, { scope: '/' });
    });
}

render(<App />, document.getElementById('root'));
