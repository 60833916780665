import RenderWhenVisible from 'Component/RenderWhenVisible';
import {
    FlexibleFormsWidget,
    ProductListWidget,
    WidgetFactoryComponent as JaidahWidgetFactoryComponent
} from 'JaidahComponent/WidgetFactory/WidgetFactory.component';

export {
    FlexibleFormsWidget,
    ProductListWidget
};

/** @namespace Geely/Component/WidgetFactory/Component */
export class WidgetFactoryComponent extends JaidahWidgetFactoryComponent {
    renderContent() {
        const {
            type,
            sliderId = null,
            displayType,
            productsCount,
            showPager,
            storeId,
            title,
            formId,
            conditionsEncoded,
            onLoad
        } = this.props;
        const {
            component: Widget,
            fallback
        } = this.renderMap[type] || {};

        if (Widget !== undefined) {
            return (
                <RenderWhenVisible fallback={ fallback }>
                    <Widget
                      form_id={ formId }
                      sliderId={ sliderId }
                      displayType={ displayType }
                      productsCount={ productsCount }
                      showPager={ showPager }
                      storeId={ storeId }
                      title={ title }
                      conditionsEncoded={ conditionsEncoded }
                      onLoad={ onLoad }
                    />
                </RenderWhenVisible>
            );
        }

        return null;
    }
}

export default WidgetFactoryComponent;
