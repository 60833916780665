import { FILTERS_POPUP_ID } from 'Component/CategoryFilterOverlay/CategoryFilterOverlay.config';
import { showPopup } from 'Store/Popup/Popup.action';
import { toggleFilterOpened } from 'Util/Browser';
import getStore from 'Util/Store';
import { getQueryParam } from 'Util/Url';

export { getPriceFilterLabel, getFiltersCount } from 'SourceUtil/Category/Filters';

/** @namespace Scandipwa/Util/Category/Filters/showFiltersPopup */
export const showFiltersPopup = () => {
    const store = getStore();
    const { dispatch } = store;

    dispatch(showPopup(FILTERS_POPUP_ID, { title: __('Filters') }));
    toggleFilterOpened(true);
};

/** @namespace Scandipwa/Util/Category/Filters/getCategoryFiltersInitialState */
export function getCategoryFiltersInitialState() {
    const { location } = window;
    const selectedFiltersString = (getQueryParam('customFilters', location) || '').split(';');

    return selectedFiltersString.reduce((acc, filter) => {
        if (!filter) {
            return acc;
        }
        const [key, value] = filter.split(':');

        return { ...acc, [key]: value.split(',') };
    }, {});
}
